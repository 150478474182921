import React, { useEffect } from 'react';
import { getComponent } from '../components';
import { DomService, StoryblokService } from '../services';
import { StoryDataFromGraphQLQuery } from '../templates/types';

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;

const syntheticStory = {
  alternates: [],
  content: {
    _uid: 'preview-login',
    component: '',
  },
  created_at: '',
  full_slug: '/preview-login-wall',
  group_id: '',
  id: undefined,
  is_startpage: false,
  meta_data: '',
  name: 'preview-login-wall',
  parent_id: null,
  position: 0,
  published_at: null,
  first_published_at: null,
  slug: '/preview-login-wall',
  sort_by_date: null,
  tag_list: [],
  uuid: '',
  lang: 'default',
};

const syntheticGlobalComponents = {
  content: {},
} as StoryDataFromGraphQLQuery;

const PreviewLoginWall = () => {
  useEffect(() => {
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());
  }, []);

  return (
    <>
      <RocheGlobalConfig
        {...DomService.getGlobalConfig(syntheticStory, syntheticGlobalComponents)}
      >
      </RocheGlobalConfig>

      <roche-header></roche-header>
      <roche-grid>
        <roche-single-sign-in style={{ textAlign: 'center' }}></roche-single-sign-in>
      </roche-grid>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PreviewLoginWall;
